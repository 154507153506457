import bombBlue from './bomb-blue.svg';
import bombRed from './bomb-red.svg';
import bombGreen from './bomb-green.svg';
import bombYellow from './bomb-yellow.svg';
import bombPurple from './bomb-purple.svg';
import bombOrange from './bomb-orange.svg';

interface PlayerTheme {
  color: 'blue' | 'red' | 'green' | 'yellow' | 'purple' | 'orange',
  bomb: string,
}

const playerThemes: PlayerTheme[] = [
  {
    color: 'blue',
    bomb: bombBlue,
  },
  {
    color: 'red',
    bomb: bombRed,
  },
  {
    color: 'green',
    bomb: bombGreen,
  },
  {
    color: 'yellow',
    bomb: bombYellow,
  },
  {
    color: 'purple',
    bomb: bombPurple,
  },
  {
    color: 'orange',
    bomb: bombOrange,
  },
];

export default function playerTheme(playerIndex: number): PlayerTheme {
  return playerThemes[playerIndex];
};