import { Button, Tooltip } from '@mui/material';
import * as React from 'react';

interface Props {
  content: string,
};

export default function CopyToClipboardButton({ content }: Props) {
  const [open, setOpen] = React.useState(false);
  return (
    <Tooltip open={open} placement="right" title="Copied!">
      <Button onClick={() => {
        navigator.clipboard.writeText(content);
        setOpen(true);
        setTimeout(() => setOpen(false), 3000);
      }}>
        Copy
      </Button>
    </Tooltip>
  );
};
