import * as React from 'react';
import useClient from './useClient';

export default function useCreateRoom() {
  const client = useClient();
  const create = React.useCallback(async (roomName: string, options?: any) => {
    const room = await client.create(roomName, options);
    sessionStorage.setItem(room.id, room.sessionId);
    await room.leave(false);
    return room.id;
  }, [client]);
  return create;
};
