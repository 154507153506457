import * as React from 'react';
import { createTheme, PaletteColorOptions, ThemeProvider as MaterialThemeProvider } from '@mui/material/styles';

declare module "@mui/material/styles" {
  interface PaletteOptions {
    neutral: PaletteColorOptions;
  }
};

const theme = createTheme({
  palette: {
    neutral: {
      main: '#64748B',
      contrastText: '#fff',
    },
  },
});

interface Props {
  children: React.ReactNode,
};

export default function ThemeProvider({ children }: Props) {
  return (
    <MaterialThemeProvider theme={theme}>
      {children}
    </MaterialThemeProvider>
  );
};