import * as React from 'react';

import useRoomProvider from './useRoomProvider';
import ClassicRoomContext from './ClassicRoomContext';
import { ClassicState } from '../../minesweeper-backend/src/rooms/schema/ClassicState';

interface Props {
  roomId: string,
  options?: any,
  children: React.ReactNode,
};

export default function ClassicRoomProvider({ roomId, options, children }: Props) {
  const roomContext = useRoomProvider<ClassicState>(roomId, options);

  return (
    <ClassicRoomContext.Provider value={roomContext}>
      {children}
    </ClassicRoomContext.Provider>
  );
};
