import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Chip, Paper, Stack } from '@mui/material';
import Bomb from './Bomb';
import useClassicRoom from './useClassicRoom';
import { Player as PlayerSchema } from '../../minesweeper-backend/src/rooms/schema/Player';
import nullthrows from 'nullthrows';


interface Props {
  playerIndex: number,
  player: PlayerSchema,
}

export default function PlayerScore({ playerIndex, player }: Props) {
  // TODO: show connection status
  const room = useClassicRoom();
  const roomState = nullthrows(room.state);
  const self = room.sessionId === player.sessionId;
  return (
    <Paper sx={{ px: 0.5 }}>
      <Stack direction="row" alignItems="center" spacing={0.5}>
        {
          self
            ? <Chip label="YOU" size="small" color="default" />
            : null
        }
        <Bomb
          playerIndex={playerIndex}
          size={40}
          animate={roomState.status === 'playing' && player.hasTurn}
        />
        <Typography sx={{ fontSize: 28 }}>{player.score.toString()}</Typography>
      </Stack>
    </Paper>
  );
}