import * as React from 'react';
import * as Colyseus from "colyseus.js";
import ClientContext from './ClientContext';

interface Props {
  address: string,
  children: React.ReactNode,
};

export default function ClientProvider({ address, children }: Props) {
  const client = React.useMemo(
    () => new Colyseus.Client(address),
    [address],
  );
  return (
    <ClientContext.Provider value={client} >
      {children}
    </ClientContext.Provider>
  );
};
