import * as React from 'react';
import { Chip } from '@mui/material';
import Bomb from './Bomb';
import useClassicRoom from './useClassicRoom';
import nullthrows from 'nullthrows';

export default function Remaining() {
  const room = useClassicRoom();
  const roomState = nullthrows(room.state);
  let remaining = roomState.board.mines;
  roomState.board.cells.forEach(cell => {
    if (cell.content === '*') {
      --remaining;
    }
  });
  return (
    <Chip sx={{ backgroundColor: 'white', fontSize: 14 }} variant="outlined" icon={<Bomb size={30} />} label={`${remaining} left`} />
  );
}