import * as React from 'react';
import * as Colyseus from 'colyseus.js';
import { Schema } from "@colyseus/schema";
import useClient from './useClient';
import { Mutex } from 'async-mutex';

import type { RoomStatus } from './createRoomContext';
import { EventEmitter } from 'events';

export default function useRoomProvider<State extends Schema>(roomId: string, options: any) {
  const client = useClient();
  const { current: mutex } = React.useRef(new Mutex());
  const { current: emitter } = React.useRef(new EventEmitter());
  const [status, setStatus] = React.useState<RoomStatus>('disconnected');
  const [connectedRoomId, setConnectedRoomId] = React.useState<null | string>(null);
  const [sessionId, setSessionId] = React.useState<null | string>(null);
  const [state, setState] = React.useState<State | null>(null);
  const [send, setSend] = React.useState(() => () => { });
  const [leave, setLeave] = React.useState(() => () => { });

  React.useEffect(() => {
    let room: null | Colyseus.Room<State> = null;
    let subscribed = true;

    const cleanup = (consented: boolean) => {
      if (!subscribed) {
        return;
      }
      subscribed = false;
      if (room) {
        const thisRoom = room;
        mutex.runExclusive(() => thisRoom.leave(consented));
      }
      setStatus('disconnected');
      setConnectedRoomId(null);
      setSessionId(null);
      setState(null);
      setSend(() => () => { });
    };

    const connect = async () => {
      await mutex.runExclusive(async () => {
        if (!subscribed) {
          return;
        }
        setStatus('connecting');
        const sessionId = sessionStorage.getItem(roomId);
        const makeRoom = sessionId
          ? () => client.reconnect<State>(roomId, sessionId)
          : () => client.joinById<State>(roomId, options);
        try {
          room = await makeRoom();
        } catch (error) {
          if (!subscribed) {
            return;
          }
          if (error instanceof Error) {
            if (error.message === `session expired: ${sessionId}`) {
              sessionStorage.removeItem(roomId);
              setTimeout(connect, 0);
              return;
            }
            if (error.message === `room "${roomId}" not found`) {
              setStatus('not-found');
              return;
            }
          }
          console.error(error);
          setStatus('error');
          setTimeout(connect, 1000);
          return;
        }
        if (!subscribed) {
          await room.leave(false);
          return;
        }
        setStatus('connected');
        setConnectedRoomId(room.id);
        setSessionId(room.sessionId);
        const thisRoom = room;
        setSend(() => (type: string, message?: any) => thisRoom.send(type, message));
        setLeave(() => () => cleanup(true));
        room.onStateChange(state => {
          setState(state.clone());
        });
        room.onMessage('*', (type, message) => {
          emitter.emit(type.toString(), message);
        });
        room.onError(console.error);
        room.onLeave((code) => {
          thisRoom.removeAllListeners();
          if (!subscribed) {
            return;
          }
          setStatus('error');
          setTimeout(connect, 1000);
        });
        sessionStorage.setItem(room.id, room.sessionId);
      });
    };

    connect();

    return () => cleanup(false);
  }, [client, mutex, roomId, options, emitter]);

  const on = React.useMemo(() => {
    return (type: string, listener: (message: any) => void) => {
      emitter.on(type, listener);
    };
  }, [emitter]);

  const off = React.useMemo(() => {
    return (type: string, listener: (message: any) => void) => {
      emitter.off(type, listener);
    };
  }, [emitter]);

  const context = React.useMemo(() => ({
    status,
    roomId: connectedRoomId,
    sessionId,
    state,
    send,
    on,
    off,
    leave,
  }), [status, connectedRoomId, sessionId, state, send, on, off, leave]);

  return context;
};
