import { Stack } from '@mui/material';
import * as React from 'react';
import ExampleCell from './ExampleCell';

interface Props {
  rows: number,
  columns: number,
};

export default function ExampleGrid({ rows, columns }: Props) {
  return (
    <Stack direction="column">
      {[...new Array(rows)].map((_, row) => (
        <Stack key={row} direction="row">
          {[...new Array(columns)].map((_, column) => (
            <ExampleCell key={column} row={row} column={column} />
          ))}
        </Stack>
      ))}
    </Stack>
  )
};