import * as React from 'react';
import { Stack } from '@mui/material';
import { Cell as CellSchema } from '../../minesweeper-backend/src/rooms/schema/Cell';
import Cell from './Cell';

interface Props {
  row: CellSchema[],
  onClick: (column: number) => void,
}

export default function Row({ row, onClick }: Props) {
  return (
    <Stack direction="row">
      {row.map((cell, index) => (
        <Cell key={index} cell={cell} onClick={() => onClick(index)} />
      ))}
    </Stack>
  );
};
