import * as React from 'react';
import useAudioPlayer from './useAudioPlayer';
import useClassicRoom from './useClassicRoom';
import bombSound from './bomb.mp3';
import zeroSound from './zero.mp3';

export default function UncoverSoundPlayer() {
  const audioPlayer = useAudioPlayer();
  const { on, off } = useClassicRoom();
  const play = React.useCallback((message: any) => {
    if (message.content === '*') {
      audioPlayer.play(bombSound);
    }
    if (message.content === '0') {
      audioPlayer.play(zeroSound);
    }
  }, [audioPlayer]);
  React.useEffect(() => {
    on('uncover', play);
    return () => off('uncover', play);
  }, [play, on, off]);

  return <></>;
}