import createRoomContext from './createRoomContext';
import { ClassicState } from '../../minesweeper-backend/src/rooms/schema/ClassicState';

import type { Room } from './createRoomContext';

export type ClassicRoom = Room<ClassicState>;

const ClassicRoomContext = createRoomContext<ClassicState>();

export default ClassicRoomContext;
