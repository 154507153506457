import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import nullthrows from 'nullthrows';
import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import useClassicRoom from './useClassicRoom';

export default function PlayAgainDialog() {
  const room = useClassicRoom();
  const [open, setOpen] = React.useState(false);
  const roomState = nullthrows(room.state);
  React.useEffect(() => {
    setOpen(false);
    if (roomState.status !== 'finished') {
      return;
    }
    const timeoutId = setTimeout(() => setOpen(true), 4000);
    return () => clearTimeout(timeoutId);
  }, [roomState.status]);
  const selfAgain = nullthrows(roomState.players.find(player => player.sessionId === room.sessionId)).playAgain;
  const allRestAgain = roomState.players.every(player => player.sessionId === room.sessionId || player.playAgain);
  const allIn = roomState.players.every(player => player.sessionId !== undefined)
  const show = open && allIn;
  return (
    <Dialog fullWidth maxWidth="xs" open={show}>
      <DialogTitle>Play again?</DialogTitle>
      <DialogContent>
        <Typography>
          {!selfAgain && !allRestAgain && 'A new game will start if all players vote to play again.'}
          {selfAgain && !allRestAgain && "You've voted to play again. Waiting for your opponent's response..."}
          {!selfAgain && allRestAgain && 'Your opponent wants to play again. Do you want to play again?'}
          {selfAgain && allRestAgain && 'A new game will start shortly...'}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          color="error"
          component={RouterLink}
          to="/"
          onClick={room.leave}
        >
          Leave game
        </Button>
        <Button
          color="primary"
          disabled={selfAgain}
          onClick={() => room.send('play again')}
        >
          Play again
        </Button>
      </DialogActions>
    </Dialog>
  );
};