import * as React from 'react';
import Container from '@mui/material/Container';
import Layout from './Layout';
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Radio, RadioGroup } from '@mui/material';
import useCreateRoom from './useCreateRoom';
import { useNavigate } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';

const sizeOptions = {
  "tiny": { rows: 4, columns: 4, mines: 4 },
  "small": { rows: 8, columns: 8, mines: 10 },
  "medium": { rows: 16, columns: 16, mines: 40 },
  "large": { rows: 16, columns: 30, mines: 99 },
};

export default function CreatePage() {
  const createRoom = useCreateRoom();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [size, setSize] = React.useState("medium");
  const [players, setPlayers] = React.useState(2);
  const [bot, setBot] = React.useState(false);
  return (
    <Layout>
      <Container sx={{ py: 8 }} maxWidth="md">
        <FormControl>
          <FormLabel>Size</FormLabel>
          <RadioGroup
            value={size}
            onChange={(_, value) => setSize(value)}
          >
            <FormControlLabel value="tiny" control={<Radio />} label="Tiny 4x4" />
            <FormControlLabel value="small" control={<Radio />} label="Small 8x8" />
            <FormControlLabel value="medium" control={<Radio />} label="Medium 16x16" />
            <FormControlLabel value="large" control={<Radio />} label="Large 30x16" />
          </RadioGroup>
          <FormLabel>Players</FormLabel>
          <RadioGroup
            value={players}
            onChange={(_, value) => setPlayers(Number.parseInt(value))}
            row
          >
            <FormControlLabel value={2} control={<Radio />} label="2" />
            <FormControlLabel value={3} control={<Radio />} label="3" />
            <FormControlLabel value={4} control={<Radio />} label="4" />
            <FormControlLabel value={5} control={<Radio />} label="5" />
            <FormControlLabel value={6} control={<Radio />} label="6" />
          </RadioGroup>
          {
            players === 2
              ? <FormGroup>
                <FormControlLabel control={<Checkbox value={bot} onChange={(_, checked) => setBot(checked)} />} label="Play against CPU" />
              </FormGroup>
              : null
          }
          <LoadingButton
            variant="contained"
            loading={loading}
            onClick={async () => {
              setLoading(true);
              let roomId = null;
              try {
                roomId = await createRoom('classic', {
                  ...sizeOptions[size as keyof typeof sizeOptions],
                  players,
                  bots: players === 2 && bot ? 1 : 0,
                });
              } catch (error) {
                setLoading(false);
                return;
              }
              navigate(`/${roomId}`);
            }}
          >
            Start
          </LoadingButton>
        </FormControl>
      </Container>
    </Layout >
  );
}