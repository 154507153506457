import nullthrows from 'nullthrows';
import * as React from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import AudioPlayerProvider from './AudioPlayerProvider';
import ClientProvider from './ClientProvider';
import CreatePage from './CreatePage';
import ExamplePage from './ExamplePage';
import GamePage from './GamePage';

export default function App() {
  return (
    <AudioPlayerProvider>
      <ClientProvider address={nullthrows(process.env.REACT_APP_API_URL)}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<CreatePage />} />
            <Route path="example" element={<ExamplePage />} />
            <Route path=":roomId" element={<GamePage />} />
          </Routes>
        </BrowserRouter>
      </ClientProvider>
    </AudioPlayerProvider>
  );
}