import * as React from 'react';
import { Paper, Typography } from '@mui/material';
import useClassicRoom from './useClassicRoom';
import party from 'party-js';
import nullthrows from 'nullthrows';


import type { ClassicRoom } from './ClassicRoomContext';
import { css, keyframes } from '@emotion/css';

const blinkKeyframes = keyframes`
  from, to {
    background-color: #ffaa00;
  }

  50% {
    background-color: #ff5500;
  }
`;

const blinkCss = css`
  animation: ${blinkKeyframes} 0.8s ease infinite;
`;


type Mode = 'default' | 'action-needed' | 'celebration';

interface Status {
  message: string,
  mode: Mode,
}

function getStatus(room: ClassicRoom): Status {
  const roomState = nullthrows(room.state);
  const player = nullthrows(roomState.players.find(player => player.sessionId === room.sessionId));
  if (roomState.status === 'waiting') {
    return {
      message: 'Waiting for opponent to join...',
      mode: 'default',
    };
  }
  if (roomState.status === 'playing') {
    if (player.hasTurn) {
      return {
        message: 'Your turn',
        mode: 'action-needed',
      };
    }
    return {
      message: "Opponent's turn",
      mode: 'default',
    }
  }
  if (roomState.status === 'finished') {
    const win = roomState.players.every(other => other === player || other.score < player.score);
    if (win) {
      return {
        message: 'You win',
        mode: 'celebration',
      };
    }

    const tie = roomState.players.every(other => other === player || other.score <= player.score);
    if (tie) {
      return {
        message: "It's a tie",
        mode: 'default',
      };
    }

    return {
      message: 'Opponent wins',
      mode: 'default',
    };
  }
  throw Error('unknown room status');
}

export default function StatusBar() {
  const ref = React.useRef<HTMLDivElement>(null);
  const room = useClassicRoom();
  const status = React.useMemo(() => getStatus(room), [room]);
  React.useEffect(() => {
    if (status.mode === 'celebration') {
      party.confetti(nullthrows(ref.current));
      const intervalId = setInterval(() => party.confetti(nullthrows(ref.current)), 2000);
      return () => clearInterval(intervalId);
    }
  }, [status.mode]);
  return (
    <Paper sx={{ display: 'flex' }} className={status.mode === 'action-needed' ? blinkCss : undefined} square>
      <Typography ref={ref} sx={{ m: 'auto', fontSize: 14, fontWeight: 'bold' }}>{status.message}</Typography>
    </Paper>
  );
}