import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Copyright from './Copyright';
import ThemeProvider from './ThemeProvider';

interface Props {
  children: React.ReactNode,
};

export default function Layout({ children }: Props) {
  return (
    <ThemeProvider>
      <CssBaseline />
      <AppBar position="relative">
        <Toolbar>
          <Typography variant="h6" color="inherit" noWrap>
            Flaggy
          </Typography>
        </Toolbar>
      </AppBar>
      <main>
        {children}
      </main>
      <Box sx={{ bgcolor: 'background.paper', p: 6 }} component="footer">
        <Copyright />
      </Box>
    </ThemeProvider>
  );
};