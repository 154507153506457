import * as React from 'react';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import { IconButton } from '@mui/material';
import useAudioPlayer from './useAudioPlayer';

export default function MuteButton() {
  const audioPlayer = useAudioPlayer();
  return (
    <IconButton onClick={audioPlayer.muted ? audioPlayer.unmute : audioPlayer.mute}>
      {audioPlayer.muted ? <VolumeOffIcon /> : <VolumeUpIcon />}
    </IconButton>
  );
}