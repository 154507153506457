import * as React from 'react';
import { Stack } from '@mui/material';
import PlayerScore from './PlayerScore';
import useClassicRoom from './useClassicRoom';
import nullthrows from 'nullthrows';

export default function Scoreboard() {
  const room = useClassicRoom();
  const roomState = nullthrows(room.state);
  return (
    <Stack direction="row" spacing={0.5}>
      {roomState.players.map((player, index) => (
        <PlayerScore key={index} playerIndex={index} player={player} />
      ))}
    </Stack>
  );
}