import * as React from 'react';

export type RoomStatus = 'disconnected' | 'connecting' | 'connected' | 'not-found' | 'error';

export interface Room<State> {
  status: RoomStatus,
  roomId: string | null,
  sessionId: string | null,
  state: State | null,
  send: (type: string, message?: any) => void,
  on: (type: string, listener: (message: any) => void) => void,
  off: (type: string, listener: (message: any) => void) => void,
  leave: () => void,
};

export default function createRoomContext<State>(): React.Context<Room<State> | null> {
  return React.createContext<Room<State> | null>(null);
}
