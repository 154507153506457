import { Box } from '@mui/material';
import * as React from 'react';
import defaultBomb from './bomb.svg';
import playerTheme from './playerTheme';
import { css, keyframes } from '@emotion/css';

const bounceKeyframes = keyframes`
  from, 20%, 53%, 80%, to {
    transform: translate3d(0,0,0);
  }

  40%, 43% {
    transform: translate3d(0, -10px, 0);
  }

  70% {
    transform: translate3d(0, -5px, 0);
  }

  90% {
    transform: translate3d(0,-2px,0);
  }
`;

const animateCss = css`
  animation: ${bounceKeyframes} 1s ease infinite;
`;

interface Props {
  playerIndex?: number,
  size?: number,
  animate?: boolean,
};

export default function Bomb({
  playerIndex,
  size = 16,
  animate = false,
}: Props) {
  const bomb = playerIndex === undefined ? defaultBomb : playerTheme(playerIndex).bomb;
  return (
    <Box
      sx={{
        width: size,
        height: size,
      }}
      className={animate ? animateCss : undefined}
    >
      <img
        style={{
          height: '100%',
          width: '100%',
        }}
        src={bomb}
        alt="Bomb"
      />
    </Box>
  );
};
