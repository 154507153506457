import * as React from 'react';
import AudioPlayerContext from './AudioPlayerContext';

interface Props {
  children: React.ReactNode,
};

export default function AudioPlayerProvider({ children }: Props) {
  const { current: playing } = React.useRef<HTMLAudioElement[]>([]);
  const [muted, setMuted] = React.useState(
    localStorage.getItem('muted') !== null
  );
  const play = React.useCallback((url: string) => {
    const audio = new Audio(url);
    audio.muted = muted;
    audio.addEventListener('ended', () => {
      const index = playing.indexOf(audio);
      playing.splice(index, 1);
    });
    playing.push(audio);
    audio.play();
  }, [playing, muted]);
  const mute = React.useCallback(() => {
    playing.forEach(audio => audio.muted = true);
    setMuted(true);
    localStorage.setItem('muted', 'true');
  }, [playing, setMuted]);
  const unmute = React.useCallback(() => {
    playing.forEach(audio => audio.muted = false);
    setMuted(false);
    localStorage.removeItem('muted');
  }, [playing, setMuted]);
  const player = React.useMemo(
    () => ({ play, muted, mute, unmute }),
    [play, muted, mute, unmute],
  );
  return (
    <AudioPlayerContext.Provider value={player}>
      {children}
    </AudioPlayerContext.Provider>
  );
}