import * as React from 'react';

interface AudioPlayer {
  play: (url: string) => void,
  muted: boolean,
  mute: () => void,
  unmute: () => void,
};

const AudioPlayerContext = React.createContext<AudioPlayer | null>(null);

export default AudioPlayerContext;
