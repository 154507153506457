import * as React from 'react';
import Game from './Game';
import useClassicRoom from './useClassicRoom';
import NotFound from './NotFound';

export default function InnerGamePage() {
  const room = useClassicRoom();
  if (room.status === 'not-found') {
    return <NotFound />;
  }
  return <Game />;
}