import * as React from 'react';
import { Box, CircularProgress } from '@mui/material';
import Bomb from './Bomb';
import Number from './Number';
import useClassicRoom from './useClassicRoom';
import { Cell as CellSchema } from '../../minesweeper-backend/src/rooms/schema/Cell';
import nullthrows from 'nullthrows';
import playerTheme from './playerTheme';

interface Props {
  cell: CellSchema,
  onClick: () => void,
};

export default function Cell({ cell, onClick }: Props) {
  const [loading, setLoading] = React.useState(false);
  React.useEffect(() => {
    setLoading(false);
  }, [cell.content]);
  const room = useClassicRoom();
  const roomState = nullthrows(room.state);
  const currentIndex = roomState.players.findIndex(player => player.hasTurn);
  const currentPlayer = roomState.players[currentIndex];
  const myTurn = room.sessionId === currentPlayer.sessionId;
  const clickable = roomState.status === 'playing' && cell.content === '?' && myTurn;
  return (
    <Box
      sx={{
        width: 40,
        height: 40,
        backgroundImage:
          cell.content === '?' || cell.content === '*' ?
            'linear-gradient(-45deg, #00BFFF, #E0FFFF)' :
            'linear-gradient(-45deg, #FFD700, #FFFFE0)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        ...clickable && {
          '&:hover': {
            border: 3,
            borderColor: playerTheme(currentIndex).color,
            borderStyle: 'dashed',
          },
        },
        ...cell.lastClick && {
          border: 2,
          borderColor: playerTheme(nullthrows(cell.playerIndex)).color,
          borderStyle: 'dashed',
        },
      }}
      onClick={clickable ? (() => {
        setLoading(true);
        onClick();
      }) : undefined}
    >
      {loading && <CircularProgress />}
      {cell.content === '*' && <Bomb playerIndex={nullthrows(cell.playerIndex)} size={40} />}
      {'1' <= cell.content && cell.content <= '8' && <Number value={cell.content} />}
    </Box>
  );
};
