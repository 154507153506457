import { Box, Typography } from '@mui/material';
import * as React from 'react';

interface Props {
  row: number,
  column: number,
};

export default function ExampleCell({ row, column }: Props) {
  return (
    <Box sx={{
      height: 50,
      width: 50,
      backgroundColor: (row + column) % 2 === 0 ? 'white' : 'black',
      flexShrink: 0,
      display: 'flex',
    }} >
      <Box sx={{ m: 'auto' }}>
        <Typography color={(row + column) % 2 === 0 ? 'black' : 'white'}>{row}, {column}</Typography>
      </Box>
    </Box>
  );
};