import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Board from './Board';
import { Box, Grid, Stack } from '@mui/material';
import Scoreboard from './Scoreboard';
import useClassicRoom from './useClassicRoom';
import ShareDialog from './ShareDialog';
import PlayAgainDialog from './PlayAgainDialog';
import nullthrows from 'nullthrows';
import UncoverSoundPlayer from './UncoverSoundPlayer';
import StatusBar from './StatusBar';
import Remaining from './Remaining';
import Controls from './Controls';

export default function Game() {
  const room = useClassicRoom();

  const { send } = room;
  const onClick = React.useCallback((row: number, column: number) => {
    send('uncover', { row, column });
  }, [send]);

  const { state } = room;
  if (state === null) {
    return <CircularProgress />;
  }

  const roomId = nullthrows(room.roomId);

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
        <Box sx={{ backgroundColor: '#ECE5DD' }}>
          <Stack
            direction="column"
            alignItems="stretch"
          >
            <Grid sx={{ p: 0.5 }} container direction="row" alignItems="center" justifyContent="space-between">
              <Grid item>
                <Scoreboard />
              </Grid>
              <Grid item>
                <Remaining />
              </Grid>
              <Grid item>
                <Controls />
              </Grid>
            </Grid>
            <StatusBar />
          </Stack>
        </Box>
        <Box sx={{ display: 'flex', flexGrow: 1, overflow: 'scroll', backgroundColor: '#ECE5DD' }}>
          <Box sx={{ m: 'auto' }}>
            <Board board={state.board} onClick={onClick} />
          </Box>
        </Box>
      </Box>
      <ShareDialog open={state.status === 'waiting'} roomId={roomId} />
      <PlayAgainDialog />
      <UncoverSoundPlayer />
    </>
  );
};
