import * as React from 'react';
import { Box, Stack } from '@mui/material';
import Row from './Row';

import { Board as BoardSchema } from '../../minesweeper-backend/src/rooms/schema/Board';

interface Props {
  board: BoardSchema,
  onClick: (row: number, column: number) => void,
};

export default function Board({ board, onClick }: Props) {
  const rows = React.useMemo(
    () => {
      const rows = [];
      for (let row = 0; row < board.rows; ++row) {
        rows.push(
          board.cells.slice(
            row * board.columns,
            (row + 1) * board.columns,
          )
        );
      }
      return rows;
    },
    [board],
  );

  return (
    <Box>
      <Stack direction="column">
        {rows.map((row, index) => (
          <Row key={index} row={row} onClick={(column: number) => onClick(index, column)} />
        ))}
      </Stack>
    </Box>
  );
};
