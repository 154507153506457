import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import * as React from 'react';
import CopyToClipboardButton from './CopyToClipboardButton';
import QrCode from './QrCode';
import { Link as RouterLink } from 'react-router-dom';
import useClassicRoom from './useClassicRoom';
import nullthrows from 'nullthrows';

interface Props {
  open: boolean,
  roomId: string,
}

export default function ShareDialog({
  open,
  roomId,
}: Props) {
  const room = useClassicRoom();
  const roomState = nullthrows(room.state);
  const url = `${process.env.REACT_APP_URL}/${roomId}`;
  return (
    <Dialog fullWidth maxWidth="xs" open={open}>
      <DialogTitle>Share game with your opponent{roomState.players.length >= 2 ? 's' : ''}</DialogTitle>
      <DialogContent>
        <Grid sx={{ py: 3 }} container direction="column" alignItems="center">
          <Grid item>
            <Typography align="center">
              {url}
            </Typography>
          </Grid>
          <Grid item>
            <CopyToClipboardButton content={url} />
          </Grid>
        </Grid>
        <QrCode content={url} />
      </DialogContent>
      <DialogActions>
        <Button
          color="error"
          component={RouterLink}
          to="/"
          onClick={room.leave}
        >
          Leave game
        </Button>
      </DialogActions>
    </Dialog>
  );
};