import * as React from 'react';
import { useParams } from "react-router-dom";
import ClassicRoomProvider from './ClassicRoomProvider';
import nullthrows from 'nullthrows';
import { CssBaseline } from '@mui/material';
import ThemeProvider from './ThemeProvider';
import InnerGamePage from './InnerGamePage';

export default function GamePage() {
  const params = useParams();
  const roomId = nullthrows(params.roomId);
  return (
    <ThemeProvider>
      <CssBaseline />
      <ClassicRoomProvider roomId={roomId}>
        <InnerGamePage />
      </ClassicRoomProvider>
    </ThemeProvider>
  );
}