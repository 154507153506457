import { Box, Button, Container, Grid, Typography } from '@mui/material';
import * as React from 'react';
import NotFoundIcon from './not-found.svg';
import { Link as RouterLink } from 'react-router-dom';

export default function NotFound() {
  return (
    <Container sx={{ pt: 3 }}>
      <Grid container direction="row">
        <Grid item>
          <img
            style={{
              height: '300px',
              width: '300px',
            }}
            src={NotFoundIcon}
            alt="Not Found"
          />
        </Grid>
        <Grid item>
          <Typography variant="h4">Game Not Found</Typography>
          <Typography>Check the URL for typos. You may have followed a link to a game which already finished.</Typography>
          <Box sx={{ width: '100%', display: 'flex' }} >
            <Button
              sx={{ mt: 3, ml: 'auto', mr: 0 }}
              color="primary"
              component={RouterLink}
              to="/"
            >
              Go to main menu
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}