import * as React from 'react';
import { Typography } from '@mui/material';

interface Props {
  value: string,
};

export default function Number({ value }: Props) {
  return (
    <Typography sx={{
      color: {
        '1': 'blue',
        '2': 'green',
        '3': 'red',
        '4': 'purple',
        '5': 'maroon',
        '6': 'turquoise',
        '7': 'black',
        '8': 'gray',
      }[value],
      fontSize: 32,
    }} >
      {value}
    </Typography>
  );
};
