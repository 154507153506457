import * as React from 'react';
import * as qrcode from 'qrcode';
import { CircularProgress } from '@mui/material';

interface Props {
  content: string,
};

export default function QrCode({ content }: Props) {
  const [image, setImage] = React.useState<null | string>(null);
  React.useEffect(
    () => {
      let subscribed = true;
      setImage(null);

      const generate = async () => {
        const image = await qrcode.toString(content, { type: 'svg' });

        if (subscribed) {
          setImage(image);
        }
      }
      generate();

      return () => {
        subscribed = false;
      };
    },
    [content],
  );

  if (!image) {
    return <CircularProgress />;
  }

  return <img src={`data:image/svg+xml;utf8,${encodeURIComponent(image)}`} alt={content} />;
};