import * as React from 'react';
import { AppBar, Box, CssBaseline, Slider, Toolbar, Typography } from '@mui/material';
import ExampleGrid from './ExampleGrid';

export default function ExamplePage() {
  const [rows, setRows] = React.useState(30);
  const [columns, setColumns] = React.useState(30);

  return (
    <>
      <CssBaseline />
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
        <Box sx={{ flexBasis: 'auto' }}>
          <AppBar position="relative" color="transparent">
            <Toolbar>
              <Box sx={{ width: 300 }} >
                <Typography>Rows: {rows}</Typography>
                <Slider
                  step={1}
                  min={1}
                  max={50}
                  value={rows}
                  onChange={(_, rows) => setRows(rows as number)}
                />
              </Box>
              <Box sx={{ ml: 4, width: 300 }} >
                <Typography>Columns: {columns}</Typography>
                <Slider
                  step={1}
                  min={1}
                  max={50}
                  value={columns}
                  onChange={(_, columns) => setColumns(columns as number)}
                />
              </Box>
            </Toolbar>
          </AppBar>
        </Box>
        <Box sx={{ flexGrow: 1, display: 'flex', overflow: 'scroll', backgroundColor: 'gray' }}>
          <Box sx={{ m: 'auto' }}>
            <ExampleGrid rows={rows} columns={columns} />
          </Box>
        </Box>
      </Box>
    </>
  )
}
